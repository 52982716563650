import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController, LoadingController, AlertController, IonThumbnail } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../../services/util.service';
import { ApiService } from '../../services/api.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { IonSelect } from '@ionic/angular';

@Component({
  selector: 'app-estoque-entrada',
  templateUrl: './estoque-entrada.component.html',
  styleUrls: ['./estoque-entrada.component.scss'],
})
export class EstoqueEntradaComponent implements OnInit {
  @ViewChild('ancoraQuantidade', null) ancoraQuantidade: ElementRef;
  @ViewChild('ancoraDatacompra', { static: false }) ancoraDatacompra: ElementRef;
  @ViewChild('ancoraDatavalidade',  { static: false }) ancoraDatavalidade: ElementRef;
  @ViewChild('ancoraLote', null) ancoraLote: ElementRef;
  @ViewChild('ancoraNotafiscal', null) ancoraNotafiscal: ElementRef;
  @ViewChild('ancoraValorunitario', null) ancoraValorunitario: ElementRef;
  @ViewChild('ancoraValortotal', null) ancoraValortotal: ElementRef;
  @ViewChild('ancoraObservacao', null) ancoraObservacao: ElementRef;

  Botao: string = "ADICIONAR"
  estoqueentradaForm: FormGroup;
  Nome:string="";
  Estoque:number=0;
  Minimo:number=0;
  Atual:number=0;
  Maximo:number=0;
  IdEstoqueEntrada:number=0;
  lRet:boolean;
  aEstoqueentrada:any=[];
  Usuario:number=0;
  constructor(  private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private loadingController: LoadingController,
    private alertController: AlertController) {

    
      this.estoqueentradaForm = formBuilder.group({
        Id: ['', ''],
        Quantidade: ['', ''],
        Datacompra: ['', ''],
        Datavalidade: ['', ''],
        Valorunitario: ['', ''],
        Valortotal: ['', ''],
        Lote: ['', ''],
        Notafiscal: ['', ''],
        Observacao: ['', ''],
       
  
      })
    
      this.Nome = this.activatedRoute.snapshot.paramMap.get('Nome');
      this.Estoque = parseInt(this.activatedRoute.snapshot.paramMap.get('Estoque'));
      this.Minimo = parseInt(this.activatedRoute.snapshot.paramMap.get('Minimo'));
      this.Atual = parseInt(this.activatedRoute.snapshot.paramMap.get('Atual'));
      this.Maximo = parseInt(this.activatedRoute.snapshot.paramMap.get('Maximo'));
      this.IdEstoqueEntrada = parseInt(this.activatedRoute.snapshot.paramMap.get('IdEstoqueEntrada'));
      this.Usuario = parseInt(this.activatedRoute.snapshot.paramMap.get('Usuario'));
   }

  ngOnInit() {}

  ionViewDidEnter() {


    setTimeout(() => {
      if (this.IdEstoqueEntrada > 0) {
        this.SelecionarDadosId(this.IdEstoqueEntrada);
      } else {
        this.limpaDados();
      }

    }, 1000)



  }

  
  async limpaDados() {
      this.estoqueentradaForm.controls["Datavalidade"].setValue('');
      this.estoqueentradaForm.controls["Id"].setValue('');
      this.estoqueentradaForm.controls["Quantidade"].setValue('');
      this.estoqueentradaForm.controls["Valorunitario"].setValue('');
      this.estoqueentradaForm.controls["Valortotal"].setValue('');
      this.estoqueentradaForm.controls["Lote"].setValue('');
      this.estoqueentradaForm.controls["Notafiscal"].setValue('');
      this.estoqueentradaForm.controls["Observacao"].setValue('');
      this.Botao = "ADICIONAR";
      let Data:string= this.utilService.solucaoGeralData(new Date(),1);
      this.estoqueentradaForm.controls["Datacompra"].setValue(Data);
      this.utilService.focusInput(this.ancoraQuantidade);


  }

  async calculaValorTotal() {
    let Quantidade:number = this.estoqueentradaForm.value.Quantidade;
    let Valorunitariolor:number = this.estoqueentradaForm.value.Valorunitario;
    let Valortotal:number= this.utilService.formateNumber(Quantidade * Valorunitariolor, 2);
    this.estoqueentradaForm.controls["Valortotal"].setValue(Valortotal);

  }

  async SelecionarDadosId(id: number) {
    this.apiService.selecionarEstoqueEntradaId(id)
      .then((response: any) => {
        if (response.length > 0) {
          this.getDados(response);

        }

      })
      .catch((response) => {
        this.utilService.showAlertCallback('Dados não encontrados.', null);
      });

  }

  async getDados(response: any) {

    
    let Datavalidade= this.utilService.solucaoGeralData(response[0].Datavalidade,1);     
    this.estoqueentradaForm.controls["Datavalidade"].setValue(Datavalidade);
  
    let Datacompra= this.utilService.solucaoGeralData(response[0].Datacompra,1);     this.estoqueentradaForm.controls["Datacompra"].setValue(Datacompra);
    console.log("Datacompra: " + Datacompra)
    this.estoqueentradaForm.controls["Datacompra"].setValue(Datacompra);

    this.estoqueentradaForm.controls["Id"].setValue(response[0].Id);
    this.estoqueentradaForm.controls["Quantidade"].setValue(response[0].Quantidade);
    this.estoqueentradaForm.controls["Valorunitario"].setValue(response[0].Valorunitario.replace(',', '.'));
    this.estoqueentradaForm.controls["Valortotal"].setValue(response[0].Valortotal.replace(',', '.'));
    this.estoqueentradaForm.controls["Lote"].setValue(response[0].Lote);
    this.estoqueentradaForm.controls["Notafiscal"].setValue(response[0].Notafiscal);
    this.estoqueentradaForm.controls["Observacao"].setValue(response[0].Observacao);


    this.Botao = "ATUALIZAR";

  }

  closeModal() {
    this.modalController.dismiss();
  }

  async testarDado() {
    let loading = await this.loadingController.create({ message: "Verificando os dados..." });
    loading.present();
    this.lRet = this.utilService.valiidarCampoGenerico("Quantidade", this.estoqueentradaForm.value.Quantidade, () => {
      this.utilService.focusInput(this.ancoraQuantidade);
    });
    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.valiidarCampoGenerico("Datacompra", this.estoqueentradaForm.value.Datacompra, () => {
      this.utilService.focusInput(this.ancoraDatacompra);
    });
    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.valiidarCampoGenerico("Datavalidade", this.estoqueentradaForm.value.Datavalidade, () => {
      this.utilService.focusInput(this.ancoraDatavalidade);
    });
    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

   
    this.lRet = this.utilService.valiidarCampoGenerico("Valorunitario", this.estoqueentradaForm.value.Valorunitario, () => {
      this.utilService.focusInput(this.ancoraValorunitario);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.valiidarCampoGenerico("Valortotal", this.estoqueentradaForm.value.Valortotal, () => {
      this.utilService.focusInput(this.ancoraValortotal);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.valiidarCampoGenerico("Lote", this.estoqueentradaForm.value.Lote, () => {
      this.utilService.focusInput(this.ancoraLote);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.valiidarCampoGenerico("Notafiscal", this.estoqueentradaForm.value.Notafiscal, () => {
      this.utilService.focusInput(this.ancoraNotafiscal);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }



    this.aEstoqueentrada = []
    loading.dismiss();
   

    setTimeout(() => {
      this.aEstoqueentrada = {
        Id: this.estoqueentradaForm.value.Id,
        Quantidade: this.estoqueentradaForm.value.Quantidade,
        Datacompra: this.estoqueentradaForm.value.Datacompra,
        Datavalidade: this.estoqueentradaForm.value.Datavalidade,
        Valorunitario: this.estoqueentradaForm.value.Valorunitario,
        Valortotal: this.estoqueentradaForm.value.Valortotal,
        Lote: this.estoqueentradaForm.value.Lote,
        Notafiscal: this.estoqueentradaForm.value.Notafiscal,
        Observacao: this.estoqueentradaForm.value.Observacao,
        Usuario: this.Usuario,
        Estoque: this.Estoque,


      }


      this.presentAlertConfirm();
    }, 1000); //1 seg



  }

  async presentAlertConfirm() {
    let sheader= 'ATUALIZAÇÃO';
    if (this.estoqueentradaForm.value.Id == '' || this.estoqueentradaForm.value.Id == null || this.estoqueentradaForm.value.Id == undefined){
     sheader= 'ADIÇÃO';
    }
    const alert = await this.alertController.create({
      header: sheader,
      message: '<strong>CONFIRMA a gravação desses dados?</strong>',
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.gravarDados();

          }
        }
      ]
    });

    await alert.present();
  }

  async gravarDados(){


    if (this.estoqueentradaForm.value.Id == '' || this.estoqueentradaForm.value.Id == null || this.estoqueentradaForm.value.Id == undefined){
      this.AdicionarDados();
    }else{
      this.AtualizarDados();
    }

  }

  async AdicionarDados() {
    this.apiService.adicionarEstoqueEntrada(this.aEstoqueentrada)
    .then((response: any) => {
      if (response[0].Retorno == 1) {
        this.utilService.showAlertCallback('Dados incluídos com sucesso!', null)
        this.estoqueentradaForm.controls["Id"].setValue( response[0].Id);
        this.Botao = "ATUALIZAR"
      }else{
        this.utilService.showAlertCallback('Esse PRODUTO DE ESTOQUE já está cadastrado!', null)

      }
     
      
    })
    .catch((response) => {
      this.utilService.showAlertCallback('Dados não poderam ser incluídos.', null);
    });

  }

  async AtualizarDados() {
    this.apiService.atualizarEstoqueEntrada(this.aEstoqueentrada)
    .then((response: any) => {
      if (response[0].Retorno == 1) {
        this.utilService.showAlertCallback('Dados atualizados com sucesso!', null);
      }
    
    })
    .catch((response) => {
      this.utilService.showAlertCallback('Dados não poderam ser atualizados.', null);
    });

  }

}
