import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class SistemaModule {
  Usuario: Array<
    {
      Id:number,
      NomeUsuario:string;
      EmailUsuario:string;
      DiaUsuario:number;
      MesUsuario:number;
      AnoUsuario:number,
      Logado:number,
      Administrador:number,
      Imagem:string,
      Coordenador:number,
      Conselho:number,
      UsuarioPesquisa: number,
      PacientePesquisa: number,
      EmpresaPesquisa: number,
      ProdutoPesquisa: number,
      ContratoPesquisa: number,
      VeiculoPesquisa: number,
      RemocaoPesquisa: number,
      EstoquePesquisa:number,
    }> = [];

    Remocao: Array<
    {
      Id:number,
      Veiculo:number;
      Condutor:number;
      Estado:number;
      Municipio:number;
      Enfermeiro:number,
      Medico:number,
      Km:number,

    }> = [];

  
    
}
