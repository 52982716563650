import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {
  Topico:string="";
  Texto:string="";
  textToSpeak:any;
  constructor(
    private activatedRoute:ActivatedRoute,
    private modalController:ModalController
  ) { 

     this.Topico = this.activatedRoute.snapshot.paramMap.get('Topico');
     this.Texto = this.activatedRoute.snapshot.paramMap.get('Texto');
  
  }

  ngOnInit() {}

  closeModal() {
    this.modalController.dismiss();
  }

  speakText(textToSpeak) {
    if (textToSpeak.trim() === '') {
      console.log("Por favor, insira algum texto.");
      return;
    }

    this.textToSpeak = textToSpeak;
    this.startMouthAnimation();

    // Verifica se a API SpeechSynthesis está disponível
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(textToSpeak);
      utterance.lang = 'pt-BR';  // Define o idioma como português
      speechSynthesis.speak(utterance);
    } else {
      console.log('API SpeechSynthesis não disponível neste navegador.');
    }
  }

  startMouthAnimation() {
    const mouthElement = document.getElementById('mouth');
    if (mouthElement) {
      // A animação da boca começa assim que a fala começa
      mouthElement.style.animationPlayState = 'running';
      
      // Esperar o tempo total da fala para parar a animação
      const duration = this.textToSpeak.length * 100; // Tempo aproximado da fala
      setTimeout(() => {
        mouthElement.style.animationPlayState = 'paused';  // Parar a animação
      }, duration);
    }
  }


}


