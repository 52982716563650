// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripe : {
    publicKey : 'pk_test_51PvnxRRq26hIBRX4Tjs1FXJikLYXKIYqX0ERgrahH0lRd4xOu4B8sSce3euwazOn0TJxWCEO8SQWhkZf8C7AV6Ru00Lsvnmj2h',
    secretKey : 'sk_test_51PvnxRRq26hIBRX4iAzIUCviWAvWDgSmBxs7VN40JV987ltL0B5XH3CW1ft59JU2uoqI9LKsFrwQDq42Y8U6lBAo00V1ADDDDV'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
