import { Component, OnInit , ViewChild, ElementRef} from '@angular/core';
import { ModalController, LoadingController, AlertController, IonThumbnail } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../../services/util.service';
import { ApiService } from '../../services/api.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['./consulta.component.scss'],
})
export class ConsultaComponent implements OnInit {
  @ViewChild('ancoraNome', null) ancoraNome: ElementRef;
  consultaForm: FormGroup;
  NomeConsulta:string="";
  Tipo:number=0
  aConsulta:any=[];
  IdPaciente:number=0;
  aVetor:any=[];
  aVinculo:any=[];
  aSimulacao:any=[]
  constructor(
    private modalController:ModalController,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private loadingController:LoadingController,
    private alertController:AlertController
  ) { 

    this.consultaForm = formBuilder.group({
    
      Nome: ['', ''],

    })
      this.NomeConsulta = this.activatedRoute.snapshot.paramMap.get('NomeConsulta');
      this.Tipo = parseInt(this.activatedRoute.snapshot.paramMap.get('Tipo'));
      this.IdPaciente =  parseInt(this.activatedRoute.snapshot.paramMap.get('IdPaciente'));
    }

  ngOnInit() {}

  closeModal() {
    this.modalController.dismiss();
  }

  async consultarDados(){
    let Nome:string= this.consultaForm.value.Nome; 
    if (Nome == '' || Nome.length < 3) {
      this.utilService.showAlertCallback('Informe o nome a ser pesquisado', ()=>{
        this.utilService.focusInput(this.ancoraNome)})
      return;
    }
    this.apiService.selecionarPacienteNome(Nome)
    .then((response: any) => {
       if (response.length > 0) {
        this.aConsulta = response;
      }else{
        this.utilService.showAlertCallback("Não exitem pacientes com esse nome", ()=>{
          this.utilService.focusInput(this.ancoraNome)
        })
      }
    
    })
    .catch((response) => {
      this.aConsulta=[];
      this.utilService.showAlertCallback('Não foi possível encontrar paciente com esse nome.', null);
    });
    

  }
  

  async atualizarVinculo(vinculo:number, responsavel:string){
    let loading = await this.loadingController.create({ message: "Verificando..." });
    loading.present();
    setTimeout(() => {
      this.aVetor = {
        Id: this.IdPaciente,
        Responsavel: responsavel,
        Vinculo: vinculo, // somete na adição
      }

      this.presentAlertConfirm(vinculo,responsavel);
    }, 1000); //1 seg

    loading.dismiss()

  }

  async presentAlertConfirm(vinculo,responsavel:string) {
    let sheader= 'VINCULAÇÃO';
    let msg: string = 'CADASTRO DE PACIENTE';
    if (this.Tipo == 2) {
      msg = 'CONTRATO';
    }
    const alert = await this.alertController.create({
      header: sheader,
      message: '<strong>CONFIRMA a vinculação deste ' + msg  + ' à ' + responsavel  + '</strong>',
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            if (this.Tipo == 1) { // vinculo para paciente
                this.gravarDadosPaciente(vinculo,responsavel);
            }else if (this.Tipo == 2)  { // vinculo para contrato
              this.selecionarDadosContrato(vinculo,responsavel);
            }

          }
        }
      ]
    });

    await alert.present();
  }

  // ESTA ROTINA É PARA O CASO DE VÍNCULO NO CADASTRO DE PACIENTES
  async gravarDadosPaciente(vinculo:number, responsavel:string) {
     let loading = await this.loadingController.create({ message: "Verificando..." });
    loading.present();
    this.apiService.atualizarPacienteVinculo(this.aVetor)
    .then((response: any) => {
      if (response[0].Retorno == 1) {
        this.utilService.showAlertCallback('Dados VINCULADOS com sucesso!', null);
        this.utilService.publishEvent("Paciente_vinculo",vinculo);
        this.utilService.publishEvent("Paciente_responsavel",responsavel);

      }
    
    })
    .catch((response) => {
      this.utilService.showAlertCallback('Dados não poderam ser VINCULADOS.', null);
    }).finally(()=>{
      loading.dismiss();
    })
  }

   // ESTA ROTINA É PARA O CASO DE VÍNCULO NO CADASTRO DE CONTRATOS
   async selecionarDadosContrato(titular:number, nome:string) {
    let loading = await this.loadingController.create({ message: "Verificando..." });
    loading.present();
    let vinculos:string='[';
    this.apiService.selecionarPacienteVinculo(titular).then((response:any)=>{
      this.aVinculo = response;
      for (let i=0; i<response.length; i++) {
        vinculos= vinculos + response[i].Id + ';'
        this.aSimulacao.push({Id: response[i].Id, Nome:  response[i].Nome, Idade:  response[i].Idade, Valor: 0})
      }
      vinculos = vinculos.substring(0, vinculos.length - 1);
      vinculos = vinculos + ']';
      this.utilService.publishEvent("Contrato_vinculo", vinculos);
      this.utilService.publishEvent("Contrato_nome",nome);
      this.utilService.publishEvent("Contrato_titular",titular);
      this.utilService.publishEvent("Contrato_simulacao",this.aSimulacao);

    }) .catch((response) => {
      this.utilService.showAlertCallback('Dados não poderam ser VINCULADOS.', null);
    }).finally(()=>{
      loading.dismiss();
    })
  }
  
   

}
